<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <!-- Charts-->
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer" id="EverPlans">
            <v-col cols="12">
                <q-agent-directory-table title="Agent Directory"></q-agent-directory-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
import QAgentDirectoryTable from '@/components/datatables/QAgentDirectoryTable.vue'
export default {
    name: "AgentDirectory",
    data() {
        return {

        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {},
    components: {
        QAgencyBuildingSubmenu,
        QAgentDirectoryTable
    }
};

</script>
