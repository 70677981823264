<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-ad-insertion-order-submenu></q-ad-insertion-order-submenu>
        <!-- Charts-->
        <div class="mx-5 q-sticky-buffer">
            <v-row justify="space-between" align="stretch" class="mx-5">
                <v-col cols="12">
                    <q-ad-insertion-market-data-table title="Ad Insertion Markets"></q-ad-insertion-market-data-table>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
import QAdInsertionOrderSubmenu from '@/components/navigation/Submenus/QAdInsertionOrderSubmenu.vue';
import QAdInsertionMarketDataTable from '@/components/datatables/QAdInsertionMarketDataTable.vue';

export default {
    name: "AdInsertionMarkets",
    data() {
        return {
            stickyTop: 0,
        };
    },
    mounted: function() {},
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        QAdInsertionOrderSubmenu,
        QAdInsertionMarketDataTable
    }
};

</script>
<style scoped>
blockquote {
    margin-left: 50px;
}

</style>
