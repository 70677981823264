<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <div class="q-sticky-buffer" v-else>
            <v-card class="pb-5 mb-5 mx-5" id="MyRecruits">
                <v-col cols="12" class="q_agency_1  pl-1 pt-3 pb-0 mb=0">
                    <div class="q-display-3 pl-5 py-2 white--text">
                        New Agent KPIs
                    </div>
                </v-col>
                <v-card-text>
                    <q-agent-recruiting-stats-new-agent :agent="agent"></q-agent-recruiting-stats-new-agent>
                </v-card-text>
            </v-card>
            <!--
            <v-card class="pb-5  mb-5 mx-5" id="RecruitingPipeline">
                <v-col cols="12" class="q_agency_1  pl-1 py-0">
                    <p class="q-display-3 pl-5 py-2 white--text">
                        Recruiting Pipeline
                    </p>
                </v-col>
                <v-row class="d-flex justify-center pa-5" height="100%">
                    <v-col cols=12>
                        <v-card flat>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="pb-5 mb-5 mx-5">
                <v-col cols="12" class="q_agency_1  pl-1 py-0" id="RecruitingLeads">
                    <p class="q-display-3 pl-5 py-2 white--text">
                        Recruiting Leads
                    </p>
                </v-col>
                <v-card flat class="mx-5">
                </v-card>
                <v-row class="d-flex justify-center pa-5 mt-5" height="100%">

                </v-row>
            </v-card>
            //-->
        </div>
    </v-container>
</template>
<script>
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Agent from '@/store/Models/Agent'
import QAgentRecruitingStatsNewAgent from '@/components/stats/QAgentRecruitingStatsNewAgent.vue';

export default {
    data: () => ({

    }),
    mounted: function() {
        this.reset();
    },
    methods: {
        reset: function() {
            this.agentcode = this.$route.params.agentcode;
            if (typeof this.agentcode == "undefined") {
                this.agentcode = this.user.Agent.AgentCode;
            }
            QuilityAPI.getAgent(this.agentcode, ['LeadershipLevel']);
        },
    },
    computed: {
        'agent': function() {
            if (typeof newV != 'undefined' && this.agentcode != newV) {
                return this.$store.state.entities.agent.currentAgent;
            }
            return this.user.Agent;
        },
        'loading': function() {
            if (typeof newV != 'undefined' && this.agentcode != newV) {
                return this.$store.state.entities.agent.loading;
            }
            return false;
        },
    },
    watch: {
        '$route.params.agentcode': function(newV, oldV) {
            if (typeof newV != 'undefined' && this.agentcode != newV) {
                this.reset();
            }
        }
    },
    components: {
        QAgentRecruitingStatsNewAgent,
        QAgencyBuildingSubmenu
    },

}

</script>
<style scoped>
.big_number {
    font-size: 3em;
    font-weight: bold;
}

.number-card {
    margin-bottom: 25px;
}

</style>
