<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <!-- Charts-->
        <div class="mx-5 q-sticky-buffer">
            <v-progress-linear color="primary" indeterminate v-if="agent == null"></v-progress-linear>
            <v-row v-else justify="space-between" align="stretch" class="mx-5">
                <v-col cols="12" id="v-agency-3">
                    <h2>{{ agent.AgentName }} Agency Structure</h2>
                </v-col>
                <v-col cols="12">
                    <q-agency-structure-chart :agent="agent"></q-agency-structure-chart>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
import QAgencyStructureChart from '@/components/charts/QAgencyStructureChart.vue'
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Agent from '@/store/Models/Agent'
export default {
    name: "AgencyDetail",
    data() {
        return {
            stickyTop: 0,
            agent: null
        };
    },
    mounted: function() {
        this.getTopAgent();
    },
    computed: {
        agent_code: function() {
            if (typeof this.$route.params.agent_code != 'undefined' && this.$route.params.agent_code != null) {
                return this.$route.params.agent_code;
            }
            return this.user.Agent.AgentCode;
        },
    },
    methods: {
        getTopAgent() {
            var g = this
            QuilityAPI.getAgentStateless(this.agent_code).then(function(json) {
                g.agent = json;
            })
        },
        loadAgent(agentcode) {
            this.$router.push('/admin/agents/' + agentcode)
        }
    },
    watch: {
        'agent_code': function() {
            this.getTopAgent();
        }
    },
    components: {
        QAgencyBuildingSubmenu,
        QAgencyStructureChart
    }
};

</script>
<style scoped>
blockquote {
    margin-left: 50px;
}

</style>
