<template>
    <div>
        <q-submenu :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" class="q-sticky"></q-submenu>
    </div>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'

export default {
    data() {
        var g = this
        return {
            title: "Ad Insertion Orders",
            titlelink: null,
        }
    },
    mounted: function() {
        //this.maybeAutoStartDemo('DemoAgency')
    },
    computed: {
        submenu: function() {
            var g = this
            if (this.hasRole(['Staff', 'ContentEditor', 'Exec', 'SuperAdmin'])) {
                return [{
                    label: "Help",
                    to: "/support"
                }]
            } else {
                return [{
                    label: "Help",
                    to: "/support"
                }]
            }
        },
        buttons: function() {
            if (this.hasRole(['SuperAdmin', 'Staff', 'Exec'])) {
                return [{
                    label: "Ad Insertion Orders",
                    to: "/agency-building/ad-insertion-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                }, {
                    label: "Manage Markets",
                    to: "/agency-building/ad-insertion-markets",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                }]
            } else if (this.hasRole(['AgencyOwner'])) {
                return [{
                    label: "Ad Insertion Orders",
                    to: "/agency-building/ad-insertion-orders",
                    icon: "fas fa-arrow-alt-circle-right",
                    id: "v-bc"
                }]
            }
            return [];
        }
    },
    components: {
        QSubmenu,
    },
    methods: {
        startDemo() {
            //this.$tours['DemoAgency'].start()
        }
    },
}

</script>
<style>
</style>
